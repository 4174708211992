import './style.scss'

export const Slide = () => {
    return (
        <div className="slide">
            <div className='content'>
                <img src="/images/slide/java-icon.svg" alt="Java Icon" />
                <img src="/images/slide/javascript-icon.svg" alt="JavaScript Icon" />
                <img src="/images/slide/typescript-icon.svg" alt="TypeScript Icon" />
                <img src="/images/slide/lua-icon.svg" alt="Lua Icon" />
                <img src="/images/slide/golang-icon.svg" alt="Golang Icon" />
                <img src="/images/slide/kotlin-icon.svg" alt="Kotlin Icon" />
                <img src="/images/slide/python-icon.svg" alt="Python Icon" />
                <img src="/images/slide/cplusplus-icon.svg" alt="C++ Icon" />
                <img src="/images/slide/csharp-icon.svg" alt="C# Icon" />

                <img src="/images/slide/nodejs-icon.svg" alt="NodeJS Icon" />
                <img src="/images/slide/springio-icon.svg" alt="Spring Boot Icon" />
                <img src="/images/slide/svelte-icon.svg" alt="Svelte Icon" />
                <img src="/images/slide/react-icon.svg" alt="React Icon" />

                <img src="/images/slide/mysql-icon.svg" alt="MySQL Icon" />
                <img src="/images/slide/mongodb-icon.svg" alt="MongoDB Icon" />
                <img src="/images/slide/redis-icon.svg" alt="Redis Icon" />
                <img src="/images/slide/postgresql-icon.svg" alt="PostgreSQL Icon" />
                <img src="/images/slide/cassandra-icon.svg" alt="Cassandra Icon" />
                <img src="/images/slide/rabbitmq-icon.svg" alt="RabbitMQ Icon" />
                <img src="/images/slide/scylladb-icon.svg" alt="ScyllaDB Icon" />
                <img src="/images/slide/couchdb-icon.svg" alt="Couchdb Icon" />
                <img src="/images/slide/mariadb-icon.svg" alt="MariaDB Icon" />
                <img src="/images/slide/prometheus-icon.svg" alt="Prometheus Icon" />
                <img src="/images/slide/apache_spark-icon.svg" alt="Apache Spark Icon" />
                <img src="/images/slide/elasticsearch-icon.svg" alt="Elasticsearch Icon" />
                
                <img src="/images/slide/docker-icon.svg" alt="Docker Icon" />
                <img src="/images/slide/kubernetes-icon.svg" alt="Kubernetes Icon" />

                <img src="/images/slide/teamcity-icon.svg" alt="TeamCity Icon" />
                <img src="/images/slide/jenkins-icon.svg" alt="Jenkins Icon" />
                <img src="/images/slide/jfrog-icon.svg" alt="JFrog Icon" />
                <img src="/images/slide/github-icon.svg" alt="GitHub Icon" />
            </div>
        </div>
    )
}