import './style.scss'

export const Footer = () => {
    return (
        <div className='footer'>
            <button type="button" onClick={(e) => {
                e.preventDefault();
                window.location.href='https://docs.google.com/document/d/13vt5pv80drK0bxFyMpwoN4YFV6m-RtWaxaPpLW_H86E';
            }}>Resume</button>
            <button type="button" onClick={(e) => {
                e.preventDefault();
                window.location.href='mailto:peaceforduck@gmail.com';
            }}>Contact</button>
        </div>
    )
}