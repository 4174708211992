import ReactDOM from 'react-dom/client';
import './style.scss'
import {Navigation} from './components/navigation/Navigation'
import {Header} from './components/header/Header'
import {Slide} from './components/slide/Slide'
import {About} from './components/about/About'
import {Footer} from './components/footer/Footer'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <> 
    <Navigation />
    <Header />
    <Slide />
    <About />
    <Footer />
  </>
);