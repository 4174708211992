import './style.scss';
import React from 'react';

export const Navigation = () => {
    return (
        <div className="navigation">
            <a href="https://api.obyvante.com">API</a>
            <a href="https://repository.obyvante.com">Repository</a>
            <a href="https://ci.obyvante.com">Continuous Integration</a>
            <a href="https://registry.obyvante.com">Docker Registry</a>
        </div>
    )
}